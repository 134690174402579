import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../Images/Logo.svg";
import { HeaderData } from "../Data/HeaderData";
import NavMenu from "./NavMenu";
import { RiMenu3Fill } from "react-icons/ri";

import AccordianMenu from "./AccordianMenu";

const Header = () => {
  const location = useLocation();
    let arr = location.pathname?.split("/");
    let lastvalue = arr[arr.length - 1].trim().replace(/-/g, " ");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <header className="bg-troo-primary py-6 top-0 sticky z-50">
      <input id="my-drawer-3" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content main_contaner">
        <div className="flex justify-between">
          <div className="mr-10 xl:mr-32">
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>

          <div className="lg:flex hidden items-center ">
            <ul className="flex items-center">
              {HeaderData.slice(0, 6).map((item, i) => {
                return <NavMenu key={i} item={item} />;
              })}
            </ul>
            <div className="">
              {HeaderData.slice(-1).map((e, i) => {
                return (
                  <Link
                    to="/hire-me"
                    className="btn-theme-secondary btn-theme-lg"
                  >
                    {e.title}
                  </Link>
                );
              })}
            </div>
          </div>

          <div className="md:block lg:hidden text-troo-white ">
            <label htmlFor="my-drawer-3">
              <RiMenu3Fill className="text-[30px] cursor-pointer" />
            </label>
          </div>
        </div>
      </div>
      <div className="drawer-side">
        <label htmlFor="my-drawer-3" className="drawer-overlay"></label>
        <div className="menu flex flex-col p-4 w-[80%] md:w-80 h-full overflow-scroll  bg-troo-deepblack text-troo-white">
          <div className="flex w-full flex-col items-start gap-4 lg:gap-6 2xl:gap-8">
            <div className="flex items-center">
              <Link to="/">
                <img
                  src={logo}
                  alt="logo"
                  className="w-[200px] md:w-[200px] xl:w-[250px]"
                />
              </Link>
            </div>
            <div className="flex w-full flex-col items-start gap-2 lg:gap-6 2xl:gap-8">
              {HeaderData.slice(0, 6).map((e, i) => {
                return !e?.subNav ? (
                  <Link key={i} to={e.path} className={`py-1 nav_link capitalize text-lg ${e.title===lastvalue ? "active" : ""}`}>
                    {e.title}
                  </Link>
                ) : (
                  <AccordianMenu title={e.title} subMenu={e.subNav} />
                );
              })}
            </div>
            <div className="">
              {HeaderData.slice(-1).map((e, i) => {
                return (
                  <Link
                  key={i}
                    to="/hire-me"
                    className="btn-theme-secondary btn-theme-lg"
                  >
                    {e.title}
                  </Link>
                );
              })}
            </div>

          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
