import React from "react";
import Banner from "../Components/Banner";
import About from "../Components/About";
import Service from "../Components/Service";
import MyPortfolio from "../Components/MyPortfolio";
import ClientReview from "../Components/ClientReview";
import BrandLogo from "../Components/BrandLogo";
import TeamComponent from "../Components/TeamComponent";
import WorkProcess from "../Components/WorkProcess";
import HighlightComponent from "../Components/HighlightComponent";
import PricingTable from "../Components/PricingTable";
import Blogs from "../Components/Blogs";
import Contacts from "../Components/Contacts";
import Layout from "../Components/Layout";
import useDocumentTitle from "../PageTitle";

const Home = () => {
  useDocumentTitle("TRoo Portfolio - React Js Landing Page Template")
  return (
    <Layout>
      <Banner />
      <About />
      <Service />
      <MyPortfolio />
      <ClientReview />
      <BrandLogo />
      <TeamComponent />
      <WorkProcess />
      <HighlightComponent />
      <PricingTable />
      <Blogs />
      <Contacts />
    </Layout>
  );
};

export default Home;
