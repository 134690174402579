import React from "react";
import portfolioMain from "../Images/portfolioDetailsMain.png";
import yoga1 from "../Images/yoga1.png";
import yoga2 from "../Images/yoga2.png";
import yoga3 from "../Images/yoga3.png";

const PortfolioDetails = () => {
  return (
    <div className="portfolioDetails bg-troo-deepblack py-12 lg:py-24">
      <div className="main_contaner">
        <h2 className="mb-7">Yoga Website Re-design For the Web</h2>
        <p className="mb-6">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum.
        </p>
        <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-y-5 mb-6 lg:mb-8">
          <div className="px-3 lg:px-10 xl:px-14 text-center">
            <h4>Client Name</h4>
            <p>Karla Anderson</p>
          </div>
          <div className="px-3 lg:px-10 xl:px-14 border-l border-troo-borderclr text-center">
            <h4>Client Name</h4>
            <p>Karla Anderson</p>
          </div>
          <div className="px-3 lg:px-10 xl:px-14 border-l border-troo-borderclr text-center">
            <h4>Client Name</h4>
            <p>Karla Anderson</p>
          </div>
          <div className="px-3 lg:px-10 xl:px-14 border-l border-troo-borderclr text-center">
            <h4>Client Name</h4>
            <p>Karla Anderson</p>
          </div>
          <div className="px-3 lg:px-10 xl:px-14 border-l border-troo-borderclr text-center">
            <h4>Client Name</h4>
            <p>Karla Anderson</p>
          </div>
        </div>
        <div className="mb-6">
          <img src={portfolioMain} alt="portfolioMain" />
        </div>
        <h3>Overview</h3>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset she
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 my-8">
          <img src={yoga1} alt="yoga1" />
          <img src={yoga2} alt="yoga2" />
          <img src={yoga3} alt="yoga3" />
        </div>
        <h3>Get The Result</h3>
        <p className="mb-6">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset she. Lorem Ipsum is simply dummy text of the
          printing and typesetting industry. Lorem Ipsum has been the industry's
          standard dummy text ever since the 1500s, when an unknown printer took
          a galley of type and scrambled it to make a type specimen book. It has
          survived not only five centuries, but also the leap into electronic
          typesetting
        </p>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised{" "}
        </p>
      </div>
    </div>
  );
};

export default PortfolioDetails;
