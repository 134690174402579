export const HeaderData = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "about me",
    path: "/about-me",
    heading: "Who I Am",
    heading2: "About Me",
  },
  {
    title: "my services",
    path: "#",
    subNav: [
      {
        subTitle: "my services",
        heading: "What i Do",
        heading2: "My Service",
        path: "/my-services",
      },

      {
        subTitle: "service grid",
        heading: "What i Do",
        heading2: "My Service",
        path: "/service-grid",
      },

      {
        subTitle: "service details",
        heading: "My Service",
        heading2: "Website Development",
        path: "/service-grid/service-details",
      },
    ],
  },
  {
    title: "Pages",
    path: "#",
    subNav: [
      {
        subTitle: "portfolio",
        heading: "See My Work",
        heading2: "My Portfolio",
        path: "/portfolio",
      },
      {
        subTitle: "portfolio details",
        heading: "Project Detail",
        heading2: "Yoga Website Re-design For the Web",
        path: "/portfolio/portfolio-details",
      },
      {
        subTitle: "testimonial",
        heading: "My Clients",
        heading2: "Clients Review",
        path: "/testimonial",
      },
      {
        subTitle: "faq",
        heading: "Frequently Asked Questions",
        heading2: "Questions In Mind?",
        path: "/faq",
      },
      {
        subTitle: "my team",
        heading: "My Team",
        heading2: "Owesome Member",
        path: "/my-team",
      },
      {
        subTitle: "pricing table",
        heading: "Affordable Price",
        heading2: "Choose The Plan",
        path: "/pricing-table",
      },
      {
        subTitle: "error 404",
        path: "/error-404",
      },
      {
        subTitle: "coming soon",
        path: "/coming-soon",
      },
    ],
  },
  {
    title: "Blogs",
    path: "#",
    subNav: [
      {
        subTitle: "blogs",
        heading: "News and Blogs",
        heading2: "Latest Blogs",
        path: "/blogs",
      },
      {
        subTitle: "blog details",
        heading: "News and Blogs",
        heading2: "What Developers Need from UX Research",
        path: "/blogs/blog-details",
      },
    ],
  },
  {
    title: "contact me",
    path: "/contact-me",
    heading: "Coffee With Me",
    heading2: "Get In Touch",
  },
  {
    title: "hire me",
    heading: "Book an Appointment",
    heading2: "Coffee With Me",
    path: "/hire-me",
  },
];
