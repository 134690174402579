import portfolio1 from "../Images/portfolio1.png";
import portfolio2 from "../Images/portfolio2.png";
import portfolio3 from "../Images/portfolio3.png";
import portfolio4 from "../Images/portfolio4.png";
import portfolio5 from "../Images/portfolio5.png";
import portfolio6 from "../Images/portfolio6.png";
import portfolio7 from "../Images/portfolio7.png";
import portfolio8 from "../Images/portfolio8.png";
import portfolio9 from "../Images/portfolio9.png";
import portfolio10 from "../Images/portfolio10.png";
import portfolio11 from "../Images/portfolio11.png";
import portfolio12 from "../Images/portfolio12.png";

export const PortfolioData = [
  {
    id: 1,
    img : portfolio1,
    test :"Logo Design",
    name : "Logo Design and Logo Mockups"
  },
  {
    id: 2,
    img : portfolio2,
    test :"Visiting Card",
    name : "A Collaboration Tool For ideas"
  },{
    id: 3,
    img : portfolio3,
    test :"Mobile App",
    name : "Online Food Delivery Mobile App Design."
  },
  {
    id: 4,
    img : portfolio4,
    test :"Web Design",
    name : "Yoga Website Re-design For the Web"
  },
  {
    id: 5,
    img : portfolio5,
    test :"Video Edit",
    name : "Social Video Create & Marketing"
  },
  {
    id: 6,
    img : portfolio6,
    test :"App Design",
    name : "Online Shopping Mobile App Design."
  },
  {
    id: 7,
    img : portfolio7,
    test :"Braning",
    name : "Website Design for Rent Bike"
  },
  {
    id: 8,
    img : portfolio9,
    test :"Product Design",
    name : "Project Management Admin Panel"
  },
  {
    id: 9,
    img : portfolio9,
    test :"Mobile App",
    name : "Online Fashion Mobile App Design."
  },{
    id: 10,
    img : portfolio10,
    test :"Web Design",
    name : "TRooLaw Website Design for Law Firm "
  },
  {
    id: 11,
    img : portfolio11,
    test :"Video Edit",
    name : "Social Post Design & Marketing"
  },

  {
    id: 12,
    img : portfolio12,
    test :"App Design",
    name : "Digital Marketing SEO Marketing"
  },
  
];
