import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import ClientReview2 from '../../Components/ClientReview2'
import useDocumentTitle from '../../PageTitle'

const TestimonialPage = () => {
  useDocumentTitle("Portfolio | Testimonials")
  return (
    <Layout>
        <BannerGlobal/>
        <ClientReview2/>
    </Layout>
  )
}

export default TestimonialPage