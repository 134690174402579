import React from "react";
import { BiCheck } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";

const PricingTable2 = () => {
  return (
    <div className="pricingTable bg-troo-deepblack py-10 lg:py-20">
      <div className="main_contaner">
        <div className="pricingText text-center">
          <h4 className="headingMini mb-6">
            Pricing Table
          </h4>
          <h2 className="max-w-[550px] m-auto">
            I Provide Plan For Your Purpose With An{" "}
            <span className="text-troo-secondary">Affordable</span>.
          </h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-12">
          <div className="pricingCard">
            <div className="service_card bg-troo-primary md:p-8 p-5">
              <h3>BASIC PLAN</h3>
              <div className="text-troo-white mb-5 relative mt-7">
                <span className="card_dollar doller">$ </span>
                <span className="card_price"> 39.00</span>{" "}
                <span className="card_dollar">/ Per Month</span>
              </div>
              <Link className="btn-theme-secondary btn-theme-lg">
                Select This Plane
              </Link>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7">
                <BiCheck />
                <p>1 Page with Elementor</p>
              </div>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7 ">
                <BiCheck />
                <p>Design Customization</p>
              </div>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7">
                <BiCheck />
                <p>1 Page with Elementor</p>
              </div>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7 opacity-50">
                <RxCross2 />
                <p>Content Upload</p>
              </div>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7 opacity-50">
                <RxCross2 />
                <p>Design Customization</p>
              </div>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7 opacity-50">
                <RxCross2 />
                <p>Unlimited Updates & Projects</p>
              </div>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7 opacity-50">
                <RxCross2 />
                <p>Custom Design & Features</p>
              </div>
            </div>
          </div>
          <div className="pricingCard relative">
            <div className="service_card bg-troo-primary md:p-8 p-5 pt-12 md:pt-0">
              <div className="absolute top-0 right-[30px] px-5 py-2 bg-gradient-to-b from-troo-secondary to-troo-tertiary">
                <h6>Populer</h6>
              </div>
              <h3>STARTER PLAN</h3>
              <div className="text-troo-white mb-5 relative mt-7">
                <span className="card_dollar doller">$ </span>
                <span className="card_price"> 79.00</span>{" "}
                <span className="card_dollar">/ Per Month</span>
              </div>
              <Link className="btn-theme-secondary btn-theme-lg">
                Select This Plane
              </Link>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7">
                <BiCheck />
                <p>1 Page with Elementor</p>
              </div>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7 ">
                <BiCheck />
                <p>Design Customization</p>
              </div>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7">
                <BiCheck />
                <p>1 Page with Elementor</p>
              </div>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7 ">
                <RxCross2 />
                <p>Content Upload</p>
              </div>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7 ">
                <RxCross2 />
                <p>Design Customization</p>
              </div>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7 opacity-50">
                <RxCross2 />
                <p>Unlimited Updates & Projects</p>
              </div>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7 opacity-50">
                <RxCross2 />
                <p>Custom Design & Features</p>
              </div>
            </div>
          </div>
          <div className="pricingCard">
            <div className="service_card bg-troo-primary md:p-8 p-5">
              <h3>BUNDLE Plan</h3>
              <div className="text-troo-white mb-5 relative mt-7">
                <span className="card_dollar doller">$ </span>
                <span className="card_price"> 99.00</span>{" "}
                <span className="card_dollar">/ Per Month</span>
              </div>
              <Link className="btn-theme-secondary btn-theme-lg">
                Select This Plane
              </Link>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7">
                <BiCheck />
                <p>1 Page with Elementor</p>
              </div>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7 ">
                <BiCheck />
                <p>Design Customization</p>
              </div>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7">
                <BiCheck />
                <p>1 Page with Elementor</p>
              </div>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7 ">
                <RxCross2 />
                <p>Content Upload</p>
              </div>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7 ">
                <RxCross2 />
                <p>Design Customization</p>
              </div>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7 ">
                <RxCross2 />
                <p>Unlimited Updates & Projects</p>
              </div>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7 ">
                <RxCross2 />
                <p>Custom Design & Features</p>
              </div>
            </div>
          </div>
          <div className="pricingCard relative">
            <div className="service_card bg-troo-primary md:p-8 p-5 pt-12 md:pt-0">
              <div className="absolute top-0 right-[30px] px-5 py-2 bg-gradient-to-b from-troo-secondary to-troo-tertiary">
                <h6>Populer</h6>
              </div>
              <h3>Golden Plan</h3>
              <div className="text-troo-white mb-5 relative mt-7">
                <span className="card_dollar doller">$ </span>
                <span className="card_price"> 89.00</span>{" "}
                <span className="card_dollar">/ Per Month</span>
              </div>
              <Link className="btn-theme-secondary btn-theme-lg">
                Select This Plane
              </Link>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7">
                <BiCheck />
                <p>1 Page with Elementor</p>
              </div>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7 ">
                <BiCheck />
                <p>Design Customization</p>
              </div>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7">
                <BiCheck />
                <p>1 Page with Elementor</p>
              </div>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7 ">
                <RxCross2 />
                <p>Content Upload</p>
              </div>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7 ">
                <RxCross2 />
                <p>Design Customization</p>
              </div>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7 opacity-50">
                <RxCross2 />
                <p>Unlimited Updates & Projects</p>
              </div>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7 opacity-50">
                <RxCross2 />
                <p>Custom Design & Features</p>
              </div>
            </div>
          </div>
          <div className="pricingCard">
            <div className="service_card bg-troo-primary md:p-8 p-5">
              <h3>Silver  Plan</h3>
              <div className="text-troo-white mb-5 relative mt-7">
                <span className="card_dollar doller">$ </span>
                <span className="card_price"> 49.00</span>{" "}
                <span className="card_dollar">/ Per Month</span>
              </div>
              <Link className="btn-theme-secondary btn-theme-lg">
                Select This Plane
              </Link>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7">
                <BiCheck />
                <p>1 Page with Elementor</p>
              </div>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7 ">
                <BiCheck />
                <p>Design Customization</p>
              </div>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7">
                <BiCheck />
                <p>1 Page with Elementor</p>
              </div>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7 opacity-50">
                <RxCross2 />
                <p>Content Upload</p>
              </div>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7 opacity-50">
                <RxCross2 />
                <p>Design Customization</p>
              </div>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7 opacity-50">
                <RxCross2 />
                <p>Unlimited Updates & Projects</p>
              </div>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7 opacity-50">
                <RxCross2 />
                <p>Custom Design & Features</p>
              </div>
            </div>
          </div>
          <div className="pricingCard">
            <div className="service_card bg-troo-primary md:p-8 p-5">
              <h3>Basic Plan</h3>
              <div className="text-troo-white mb-5 relative mt-7">
                <span className="card_dollar doller">$ </span>
                <span className="card_price"> 29.00</span>{" "}
                <span className="card_dollar">/ Per Month</span>
              </div>
              <Link className="btn-theme-secondary btn-theme-lg">
                Select This Plane
              </Link>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7">
                <BiCheck />
                <p>1 Page with Elementor</p>
              </div>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7 ">
                <BiCheck />
                <p>Design Customization</p>
              </div>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7">
                <BiCheck />
                <p>1 Page with Elementor</p>
              </div>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7 ">
                <RxCross2 />
                <p>Content Upload</p>
              </div>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7 ">
                <RxCross2 />
                <p>Design Customization</p>
              </div>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7 ">
                <RxCross2 />
                <p>Unlimited Updates & Projects</p>
              </div>
              <div className="flex items-center text-troo-white gap-3 pt-4 lg:pt-7 ">
                <RxCross2 />
                <p>Custom Design & Features</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingTable2;
