import React from 'react'
import Layout from '../../Components/Layout'

import Error404 from '../../Components/Error404'
import useDocumentTitle from '../../PageTitle'

const ErrorPage = () => {
  useDocumentTitle("Portfolio | Error404")
  return (
   <Layout>
   
    <Error404/>
   </Layout>
  )
}

export default ErrorPage