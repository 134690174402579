import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import Service2 from '../../Components/Service2'
import MyPortfolio from '../../Components/MyPortfolio'
import TeamComponent from '../../Components/TeamComponent'
import WorkProcess from '../../Components/WorkProcess'
import HighlightComponent from '../../Components/HighlightComponent'
import Contacts from '../../Components/Contacts'
import useDocumentTitle from '../../PageTitle'

const ServiceGridPage = () => {
  useDocumentTitle("Portfolio | Service Grid")
  return (
   <Layout>
    <BannerGlobal/>
    <Service2/>
    <MyPortfolio/>
    <TeamComponent/>
    <WorkProcess/>
    <HighlightComponent/>
    <Contacts/>
   </Layout>
  )
}

export default ServiceGridPage