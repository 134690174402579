import React from "react";
import { Link } from "react-router-dom";

const HighlightComponent = () => {
  return (
    <div className="highlight">
      <div className="main_contaner">
        <div className="hightlight_text md:flex justify-between items-center py-12 xl:py-24">
          <h2 className="md:max-w-[420px] max-w-[100%] md:mb-0 mb-6">
            I Create Products That Make
            <span className="textGradient">  People Happy</span>
          </h2>
          <h3 className="mr-12 flex"><Link className="viewDetails">Contact Me</Link> </h3>
        </div>
      </div>
    </div>
  );
};

export default HighlightComponent;
