import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import TeamComponent2 from '../../Components/TeamComponent2'
import useDocumentTitle from '../../PageTitle'

const TeamPage = () => {
  useDocumentTitle("Portfolio | Team Page")
  return (
    <Layout>
        <BannerGlobal/>
        <TeamComponent2/>
    </Layout>
  )
}

export default TeamPage