import React from "react";
import { ServiceData } from "../Data/ServiceData";
import { Link, createSearchParams } from "react-router-dom";
import CounterUp from "./CounterUp";
import { BsArrowRight } from 'react-icons/bs';

const Service2 = () => {
  return (
    <div className="bg-troo-primary seviceBG py-12">
      <div className="main_contaner">
        <div className="md:flex justify-between ">
          <div className="service_heading">
            <div className="sub_heading design">Services</div>
            <h4 className="headingMini mb-6">
              My Services
            </h4>
            <h2 className="pb-8">
              <span className="text-troo-secondary">What I Provide</span> To My
              Clients
            </h2>
          </div>
          <div className="flex items-center md:justify-end ">
            <Link className="btn-theme-secondary btn-theme-lg">
              See All My Services
            </Link>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 text-center">
          {ServiceData.map((e, i) => (
           <Link to={`/service-grid/service-details?${createSearchParams({id:e.id})}`}>
            <div className="service_card relative text-center px-5 py-7 card_grid bg-troo-deepblack" key={i}>
              <div className="flex justify-center mb-5">
                <div className=" text-troo-white border border-troo-secondary rounded-full flex justify-center items-center w-[50px] h-[50px] ">
                  0{e.id}
                </div>
              </div>
              <div className="border-y border-y-troo-hrtag py-2 mb-5">
                <h3>{e.name}</h3>
              </div>
              <p className="mb-5">{e.para}</p>
              <div className="card_grid_arrow">
                <BsArrowRight/>
              </div>
            </div>
           </Link>
          ))}
        </div>
        <CounterUp />
      </div>
    </div>
  );
};

export default Service2;
