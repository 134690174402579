import './App.css';
import {BrowserRouter, Routes, Route } from "react-router-dom"
import Home from './Routes/Home';
import AboutPage from './Routes/AboutPage';
import ServiceListPage from './Routes/Service/ServiceListPage';
import ServiceGridPage from './Routes/Service/ServiceGridPage';
import ServiceDetailsPage from './Routes/Service/ServiceDetailsPage';
import PortfolioPage from './Routes/Pages/PortfolioPage';
import PortfolioDetailsPage from './Routes/Pages/PortfolioDetailsPage';
import TestimonialPage from './Routes/Pages/TestimonialPage';
import TeamPage from './Routes/Pages/TeamPage';
import PricingTablePage from './Routes/Pages/PricingTablePage';
import ErrorPage from './Routes/Pages/ErrorPage';
import ComingSoonPage from './Routes/Pages/ComingSoonPage';
import BlogPage from './Routes/Blogs/BlogPage';
import BlogDetailsPage from './Routes/Blogs/BlogDetailsPage';
import ContactPage from './Routes/ContactPage';
import FaqPage from './Routes/Pages/FaqPage';
import HirePage from './Routes/HirePage';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/about-me' element={<AboutPage/>} />
        <Route path='/my-services' element={<ServiceListPage/>} />
        <Route path='/service-grid' element={<ServiceGridPage/>} />
        <Route path='/service-grid/service-details' element={<ServiceDetailsPage/>} />
        <Route path='/portfolio' element={<PortfolioPage/>} />
        <Route path='/portfolio/portfolio-details' element={<PortfolioDetailsPage/>} />
        <Route path='/testimonial' element={<TestimonialPage/>} />
        <Route path='/faq' element={<FaqPage/>} />
        <Route path='/my-team' element={<TeamPage/>} />
        <Route path='/pricing-table' element={<PricingTablePage/>} />
        <Route path='/error-404' element={<ErrorPage/>} />
        <Route path='/coming-soon' element={<ComingSoonPage/>} />
        <Route path='/blogs' element={<BlogPage/>} />
        <Route path='/blogs/blog-details' element={<BlogDetailsPage/>} />
        <Route path='/contact-me' element={<ContactPage/>} />
        <Route path='/hire-me' element={<HirePage/>} />
        
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
