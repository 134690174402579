import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";


const AccordianMenu = ({ title, subMenu }) => {
	const [isActive, setIsActive] = useState(false);

	const location = useLocation();
    let arr = location.pathname?.split("/");
    let lastvalue = arr[arr.length - 1].trim().replace(/-/g, " ");

	const handleAccordionClick = (e) => {
		setIsActive(e.target.checked && !isActive);
	};

	return (
		<div
			className={`collapse collapse-arrow min-h-0 p-0 py-1 cursor-pointer rounded-none ${
				isActive ? " collapse-open" : "  collapse-close"
			}`}
		>
			<input
				type="radio"
				name="accordian"
				className="p-0"
				onClick={handleAccordionClick}
			/>
			<p className={`collapse-title height-0 p-0 text-lg capitalize md:text-base ${title===lastvalue ?"active" : ""}`}>
				{title}
			</p>
			<div className="collapse-content p-0 text-base pb-0	">
				<div className="flex flex-col mt-2 px-4">
					{subMenu?.map((menu, i) => {
						return (
							<Link
								key={i}
								to={menu.path}
								className={`py-2 capitalize ${menu.subTitle===lastvalue ? "active" : ""}`}
							>
								{menu.subTitle}
							</Link>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default AccordianMenu;