import React from 'react'
import ComingSoon from '../../Components/ComingSoon'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import useDocumentTitle from '../../PageTitle'

const ComingSoonPage = () => {
  useDocumentTitle("Portfolio | Coming Soon")
  return (
   <>
   <Header/>
    <ComingSoon/>
    <Footer/>
   </>
   
  )
}

export default ComingSoonPage