export const WorkData = [
  {
    id: 1,
    name: "Application",
    para: "Lorem Ipsum is simply dummy this is text of the abcd edg hi j printing and typesetting industry. Lorem Ipsum has been industry's standard dummy text ever since the.",
  },
  {
    id: 2,
    name: "Analytics",
    para: "Lorem Ipsum is simply dummy this is text of the abcd edg hi j printing and typesetting industry. Lorem Ipsum has been industry's standard dummy text ever since the.",
  },
  {
    id: 3,
    name: "Working on The Project",
    para: "Lorem Ipsum is simply dummy this is text of the abcd edg hi j printing and typesetting industry. Lorem Ipsum has been industry's standard dummy text ever since the.",
  },
  {
    id: 4,
    name: "Closing The Project",
    para: "Lorem Ipsum is simply dummy this is text of the abcd edg hi j printing and typesetting industry. Lorem Ipsum has been industry's standard dummy text ever since the.",
  },
];
