import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import BlogDetails from '../../Components/BlogDetails'
import useDocumentTitle from '../../PageTitle'

const BlogDetailsPage = () => {
  useDocumentTitle("Portfolio | Blogs Details")
  return (
    <Layout>
        <BannerGlobal/>
        <BlogDetails/>
    </Layout>
  )
}

export default BlogDetailsPage