import React from "react";
import Layout from "../../Components/Layout";
import BannerGlobal from "../../Components/BannerGlobal";
import Accordian from "../../Components/Accordian";
import { FaqsData } from "../../Data/FAQsData";
import { Link } from "react-router-dom";
import useDocumentTitle from "../../PageTitle";

const FaqPage = () => {
  useDocumentTitle("Portfolio | FAQ Page")
  return (
    <Layout>
      <BannerGlobal />
      <div className="bg-troo-deepblack py-12 lg:py-24">
        <div className="main_contaner ">
          <div className="text-center mb-8">
            <h2 className="max-w-[650px] m-auto">The Most Common Asked Questions My Clients</h2>
          </div>
          <div className="lg:flex gap-6 mb-10">
            <div className="flex flex-col gap-4 items-start">
              {FaqsData.slice(0, 5).map((data, i) => {
                return <Accordian key={i} title={data.name} desc={data.para} />;
              })}
            </div>
            <div className="flex flex-col gap-4 items-start">
              {FaqsData.slice(5, 10).map((data, i) => {
                return <Accordian key={i} title={data.name} desc={data.para} />;
              })}
            </div>
          </div>
          <div className="text-center">
            <p className="text-troo-secondary mb-3">
              Still Have You Questions?
            </p>
            <Link className="btn-theme-secondary btn-theme-lg">Contact Me</Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FaqPage;
