import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import PortfolioDetails from '../../Components/PortfolioDetails'
import useDocumentTitle from '../../PageTitle'

const PortfolioDetailsPage = () => {
  useDocumentTitle("Portfolio | Portfolio Details")
  return (
    <Layout>
        <BannerGlobal/>
        <PortfolioDetails/>
    </Layout>
  )
}

export default PortfolioDetailsPage