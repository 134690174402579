import React from "react";
import Layout from "../Components/Layout";
import BannerGlobal from "../Components/BannerGlobal";
import Contacts from "../Components/Contacts";
import useDocumentTitle from "../PageTitle";

const HirePage = () => {
  useDocumentTitle("Portfolio | Hire Me")
  return (
    <Layout>
      <BannerGlobal />
      <Contacts />
    </Layout>
  );
};

export default HirePage;
