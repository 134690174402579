import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import PricingTable2 from '../../Components/PricingTable2'
import useDocumentTitle from '../../PageTitle'

const PricingTablePage = () => {
  useDocumentTitle("Portfolio | Pricing Table")
  return (
    <Layout>
        <BannerGlobal/>
        <PricingTable2/>
    </Layout>
  )
}

export default PricingTablePage