import React from "react";
import { TeamData } from "../Data/TeamData";

const TeamComponent2 = () => {
  return (
    <div className="team2 bg-troo-deepblack py-12 lg:py-24">
      <div className="main_contaner">
      <div className="reviewHeading text-center mb-5">
        <h4 className="headingMini">
          My Owesome Team
        </h4>
        <h2 className="py-7 max-w-[550px] m-auto">
          Let’s Make Something Together
          <span className="text-troo-secondary"> Start a Conversation</span>
        </h2>
      </div>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8">
          {TeamData.map((e, i) => (
            <div className="teamBox" key={i}>
              <img className="w-full" src={e.img} alt="team1" />
              <div className="absolute bottom-0 left-0 pl-12 pb-12 ">
                <h3>{e.name} </h3>
                <h6>{e.desg} </h6>
              </div>
              <div className="absolute top-[50%] left-[30%] flex gap-2 ">
                {e.social.map((item, i) => (
                  <div
                    className="teamicon"
                    key={i}
                  >
                    {item.icon}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeamComponent2;
