import React from "react";
import { Link } from "react-router-dom";
import boll from "../Images/boll.png";
import beBoll from "../Images/beBoll.png";
import { ClientData } from "../Data/ClientData";

const ClientReview = () => {
  return (
    <div className="testimonial bg-troo-primary pt-12 lg:pt-24">
      <div className="main_contaner">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <div className="py-0 md:py-12 lg:py-36">
            <div className="sub_heading design">Testimonial</div>
            <h4 className="headingMini">
              Clients Review
            </h4>
            <h2 className="py-7 max-w-[500px]">
              What My Loved{" "}
              <span className="textGradient">Clients Say</span> About Me.
            </h2>
            <p className="pb-8">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took.
            </p>
            <Link to="/testimonial" className="btn-theme-secondary btn-theme-lg ">See All Review</Link>
            <div className="flex flex-col lg:flex-row items-start lg:items-center whitespace-nowrap gap-6 mt-12">
              <div className="flex items-center justify-between gap-2">
                <img src={boll} alt="boll" className="w-14 h-14 rounded-full" />
                <div>
                  <h4>12k</h4>
                  <p>Dribbble Followers</p>
                </div>
              </div>
              <div className="flex items-center whitespace-nowrap justify-between gap-2">
                <img
                  src={beBoll}
                  alt="boll"
                  className="w-14 h-14 rounded-full"
                />
                <div>
                  <h4>12k</h4>
                  <p>Behance Followers</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            {ClientData.slice(0,3).map((e, i) => (
              <div className="border-b border-troo-hrtag pb-5">
                <div className="service_card p-5">
                  <div className="md:flex justify-between pb-5">
                    <div className="flex items-center gap-3">
                      <img className="w-20 h-20" src={e.img} alt="client1" />
                      <div>
                        <h3 className="textGradient">{e.name}</h3>
                        <p>{e.sub} </p>
                      </div>
                    </div>
                    <div className="flex items-center md:justify-end text-troo-secondary">
                      {e.star}
                    </div>
                  </div>
                  <p>{e.para}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientReview;
