import React from "react";

import { Link, createSearchParams } from "react-router-dom";
import { PortfolioData } from "../Data/PortfolioData";

const MyPortfolio2 = () => {
  return (
    <div className="bg-troo-deepblack myportfolio2 lg:py-24 py-12">
      <div className="main_contaner">
        <div className="portfolioImg md:flex justify-between gap-5">
          <div className="md:w-[36%] flex flex-col gap-5">
          {PortfolioData.slice(0, 2).map((e, i) => (
              <Link
                to={`/portfolio/portfolio-details?${createSearchParams({
                  id: e.id,
                })}`}
                key={i}
                className="group transition-all"
              >
                <div className="relative overflow-hidden">
                  <img className="rounded-md transition ease-in-out duration-500 group-hover:scale-125" src={e.img} alt="port1" />
                  <div className="portfolio_img_text absolute bottom-0 left-0 pl-5 pb-5">
                    <h6 className="p-2 bg-troo-primary inline-block rounded mb-2 btn-theme">
                      {e.test}
                    </h6>
                    <h3 className="max-w-[200px]">{e.name}</h3>
                  </div>
                </div>
              </Link>
            ))}
          </div>
          <div className="md:w-[74%] mt-7 md:mt-0">
          {PortfolioData.slice(2, 3).map((e, i) => (
              <Link
                to={`/portfolio/portfolio-details?${createSearchParams({
                  id: e.id,
                })}`}
                key={i}
                className="group transition-all"
              >
                <div className="relative overflow-hidden">
                  <img className="rounded-md transition ease-in-out duration-500 group-hover:scale-125" src={e.img} alt="port3" />
                  <div className="portfolio_img_text absolute bottom-0 left-0 pl-5 md:pl-12 pb-5 md:pb-12">
                    <h6 className="p-2 bg-troo-primary inline-block rounded mb-2 btn-theme">
                      {e.test}
                    </h6>
                    <h3 className="max-w-[300px]">{e.name}</h3>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
        <div className="portfolioImg md:flex justify-between gap-5 mt-5">
          <div className="md:w-[74%]">
          {PortfolioData.slice(3, 4).map((e, i) => (
              <Link
                to={`/portfolio/portfolio-details?${createSearchParams({
                  id: e.id,
                })}`}
                key={i}
                className="group transition-all"
              >
                <div className="relative overflow-hidden">
                  <img className="rounded-md transition ease-in-out duration-500 group-hover:scale-125" src={e.img} alt="port1" />
                  <div className="portfolio_img_text absolute bottom-0 right-0 pr-6 pb-10 text-right">
                    <h6 className="p-2 bg-troo-primary inline-block rounded mb-2 btn-theme">
                      {e.test}
                    </h6>
                    <h3 className="max-w-[300px]">{e.name}</h3>
                  </div>
                </div>
              </Link>
            ))}
          </div>
          <div className="md:w-[36%] flex flex-col gap-5 mt-7 md:mt-0">
          {PortfolioData.slice(4, 6).map((e, i) => (
              <Link
                to={`/portfolio/portfolio-details?${createSearchParams({
                  id: e.id,
                })}`}
                key={i}
                className="group transition-all"
              >
                <div className="relative overflow-hidden">
                  <img className="rounded-md transition ease-in-out duration-500 group-hover:scale-125" src={e.img} alt="port1" />
                  <div className="portfolio_img_text absolute bottom-0 right-0 pr-6 pb-6 text-right">
                    <h6 className="p-2 bg-troo-primary inline-block rounded mb-2 btn-theme">
                      {e.test}
                    </h6>
                    <h3 className="max-w-[300px]">{e.name}</h3>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
        <div className="portfolioImg md:flex justify-between mt-5 gap-5">
          <div className="md:w-[36%] flex flex-col gap-5">
          {PortfolioData.slice(6,8).map((e, i) => (
              <Link
                to={`/portfolio/portfolio-details?${createSearchParams({
                  id: e.id,
                })}`}
                key={i}
                className="group transition-all"
              >
                <div className="relative overflow-hidden">
                  <img className="rounded-md transition ease-in-out duration-500 group-hover:scale-125" src={e.img} alt="port1" />
                  <div className="portfolio_img_text absolute bottom-0 left-0 pl-5 pb-5">
                    <h6 className="p-2 bg-troo-primary inline-block rounded mb-2 btn-theme">
                      {e.test}
                    </h6>
                    <h3 className="max-w-[200px]">{e.name}</h3>
                  </div>
                </div>
              </Link>
            ))}
          </div>
          <div className="md:w-[74%] mt-7 md:mt-0">
          {PortfolioData.slice(8,9).map((e, i) => (
              <Link
                to={`/portfolio/portfolio-details?${createSearchParams({
                  id: e.id,
                })}`}
                key={i}
                className="group transition-all"
              >
                <div className="relative overflow-hidden">
                  <img className="rounded-md transition ease-in-out duration-500 group-hover:scale-125" src={e.img} alt="port3" />
                  <div className="portfolio_img_text absolute bottom-0 left-0 pl-5 md:pl-12 pb-5 md:pb-12">
                    <h6 className="p-2 bg-troo-primary inline-block rounded mb-2 btn-theme">
                      {e.test}
                    </h6>
                    <h3 className="max-w-[300px]">{e.name}</h3>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
        <div className="portfolioImg md:flex justify-between gap-5 mt-5">
          <div className="md:w-[74%]">
          {PortfolioData.slice(9,10).map((e, i) => (
              <Link
                to={`/portfolio/portfolio-details?${createSearchParams({
                  id: e.id,
                })}`}
                key={i}
                className="group transition-all"
              >
               <div className="relative overflow-hidden">
                  <img className="rounded-md transition ease-in-out duration-500 group-hover:scale-125" src={e.img} alt="port1" />
                  <div className="portfolio_img_text absolute bottom-0 right-0 pr-6 pb-5 md:pb-10 text-right">
                    <h6 className="p-2 bg-troo-primary inline-block rounded mb-2 btn-theme">
                      {e.test}
                    </h6>
                    <h3 className="max-w-[300px]">{e.name}</h3>
                  </div>
                </div>
              </Link>
            ))}
          </div>
          <div className="md:w-[36%] flex flex-col gap-5 mt-7 md:mt-0">
          {PortfolioData.slice(10,12).map((e, i) => (
              <Link
                to={`/portfolio/portfolio-details?${createSearchParams({
                  id: e.id,
                })}`}
                key={i}
                className="group transition-all"
              >
               <div className="relative overflow-hidden">
                  <img className="rounded-md transition ease-in-out duration-500 group-hover:scale-125" src={e.img} alt="port1" />
                  <div className="portfolio_img_text absolute bottom-0 right-0 pr-6 pb-6 text-right">
                    <h6 className="p-2 bg-troo-primary inline-block rounded mb-2 btn-theme">
                      {e.test}
                    </h6>
                    <h3 className="max-w-[300px]">{e.name}</h3>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyPortfolio2;
