import React, { useEffect, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import bannerimg from "../Images/bannerGlobalImg.png";
import { BlogData } from "../Data/BlogData";
import { PortfolioData } from "../Data/PortfolioData";
import { HeaderData } from "../Data/HeaderData";
import { ServiceData } from "../Data/ServiceData";

const BannerGlobal = () => {
  const [heading, setHeading] = useState();
  const [heading2, setHeading2] = useState();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  let pathName = location.pathname;

  useEffect(() => {
    const id = searchParams.get("id");
    if (id) {
      if (pathName.includes("service-details")) {
        const mData = ServiceData.find((e) => e.id == id);
        setHeading2(mData.name);
      }
      if (pathName.includes("blog-details")) {
        const bData = BlogData.find((e) => e.id == id);
        setHeading2(bData.name);
        setHeading(bData.test);
      }
      if (pathName.includes("portfolio-details")) {
        const pData = PortfolioData.find((e) => e.id == id);
        setHeading2(pData.name);
        setHeading(pData.test);
      }
    } else {
      const data = HeaderData.find((data) => {
        if (data?.subNav) {
          const subTitel = data?.subNav?.find((s) => s.path === pathName);
          if (subTitel) {
            return data;
          }
        } else if (data.path === pathName) {
          return data;
        }
      });

      if (data?.subNav) {
        const obj = data?.subNav.find((s) => s.path === pathName);
        setHeading(obj.heading);
        setHeading2(obj.heading2);
      } else {
        setHeading(data.heading);
        setHeading2(data.heading2);
      }
    }
  }, []);

  useEffect(() => {
    const pathnamee = location.pathname.split("/").filter((p) => !!p);
    const breadcrumbs = pathnamee.map((e, i) => ({
      title: e,
      path: `/${pathnamee.slice(0, i + 1).join("/")}`,
    }));
    setBreadcrumbs(breadcrumbs);
  }, [location]);
  return (
    <div className="bg-troo-primary bannerGlobalBg overflow-hidden py-12">
      <div className="main_contaner md:flex items-center w-full">
        <div className="flex w-full flex-col items-center  GlobalText">
          <div className="sub_heading text-center design">{heading}</div>
          <div className="sub_heading text-center textGradient">{heading2}</div>
          <div className="text-sm flex items-center justify-center ">
            <ul className="flex flex-wrap gap-1 justify-center bg-gradient-to-b from-troo-secondary to-troo-tertiary px-5 py-3 rounded">
              <Link to="/" className="">
                Home
              </Link>
              <span>&#47;</span>
              {breadcrumbs.map((breadcrumb, index) => (
                <>
                  <li
                    className={` capitalize ${
                      index === breadcrumbs.length - 1 ? " current" : ""
                    } ${breadcrumb.path === "/faq" && "uppercase"}`}
                  >
                    <Link key={index} to={breadcrumb.path}>
                      {breadcrumb.title.replace(/-/g, " ")}
                    </Link>
                  </li>
                  {index !== breadcrumbs?.length - 1 && <span>&#47;</span>}
                </>
              ))}
            </ul>
          </div>
        </div>
        <div className="md:self-end  md:place-self-end flex justify-center mt-8 md:mt-0">
          <img className="max-w-[400px]" src={bannerimg} alt="Global Img" />
        </div>
      </div>
    </div>
  );
};

export default BannerGlobal;
