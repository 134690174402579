export const ServiceData = [
    {
        id : 1,
        name : "UI/UX Design",
        para : "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard dummy text.",
    },
    {
        id : 2,
        name : "Web Development",
        para : "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard dummy text.",
    },
    {
        id : 3,
        name : "Video Editing",
        para : "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard dummy text.",
    },
    {
        id : 4,
        name : " Mobile App Design",
        para : "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard dummy text.",
    },
    {
        id : 5,
        name : "Game Development",
        para : "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard dummy text.",
    },
    {
        id : 6,
        name : "Graphic Design",
        para : "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard dummy text.",
    },
    {
        id : 7,
        name : "Game Design",
        para : "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard dummy text.",
    },
    {
        id : 8,
        name : " Animation",
        para : "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard dummy text.",
    },
    {
        id : 9,
        name : " Video Eiting",
        para : "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard dummy text.",
    }
]