import React from "react";
import { Link } from "react-router-dom";
import logo from "../Images/Logo.svg";

const Footer = () => {
  return (
    <div className="footerBg bg-troo-primary pt-24">
      <div className="main_contaner">
        <div className="border-y border-troo-secondary border-opacity-5 py-8 md:flex justify-between">
          <div className="mb-8 md:mb-0">
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <ul className="flex items-center flex-wrap justify-center">
            <li className="nav_item">
              <Link to="/">Home</Link>
            </li>
            <li className="nav_item">
              <Link to="/about-me">About Me</Link>
            </li>
            <li className="nav_item">
              <Link to="/portfolio">Portfolio</Link>
            </li>
            <li className="nav_item">
              <Link to="/blogs">My Blogs</Link>
            </li>
            <li className="nav_item">
              <Link to="/contact-me">Contact Me</Link>
            </li>
          </ul>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 py-12">
          <div className="text-left">
            <h3 className="pb-5">About Me</h3>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever it’s since the 1500s, when an unknown printer took a galley
              of type and scrambled it to make
            </p>
          </div>
          <div className="text-right">
            <h3 className="text-right pb-5">My Services</h3>
            <ul className="md:flex justify-end gap-5">
              <li className="p">Mobile App Development</li>
              <li className="p">Website Development</li>
              <li className="p">Logo & Branding</li>
            </ul>
            <ul className="md:flex justify-end gap-5">
              <li className="p">Video Editing</li>
              <li className="p">Pay-Per-Click</li>
              <li className="p">Social Media Marketing</li>
            </ul>
          </div>
        </div>
        <div className="border-y border-troo-secondary border-opacity-5 pt-4 md:flex justify-between">
          <p>
            Copyright © 2022. <Link className="font-bold">troothemes</Link>. All
            rights reserved.
          </p>
          <div className="flex justify-between gap-5">
            <p>Terms & Conditions</p>
            <p>Privacy Policy</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
