import React from "react";
import serviceDetails1 from "../Images/serviceDetails1.png";
import serviceDetails2 from "../Images/serviceDetails2.png";
import { BsCheckCircle } from "react-icons/bs";

const ServiceDetails = () => {
  return (
    <div className="ServiceDetails w-full bg-troo-deepblack py-12 lg:py-24 ">
      <div className="main_contaner">
        <div className="flex flex-col lg:flex-row h-full items-start gap-6">
          <div className="w-full lg:w-[70%] flex flex-col items-start gap-5">
            <h2>Description</h2>
            <h3>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Vestibulum velit tortor, interdum sed cursus.
            </h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Vestibulum velit tortor, interdum sed cursus eu, sagittis ut nunc.
              Sed vitae tellus et arcu aliquet faucibus fermentum non
              lacus.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Vestibulum velit tortor, interdum sed cursus eu, sagittis ut nunc.
              Sed vitae tellus et arcu aliquet faucibus fermentum non lacusipsum
              dolor sit amet, consectetur adipis
            </p>
            <p>
              Lorem ipsum dolor sit amet,{" "}
              <span className="text-troo-secondary">
                consectetur adipiscing elit
              </span>
              . Vestibulum velit tortor, interdum sed cursus eu, sagittis ut
              nunc. Sed vitae tellus et arcu aliquet faucibus fermentum non
              lacus.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Vestibulum velit tortor, interdum sed cursus eu, sagittis ut nunc.
              Sed vitae tellus et arcu aliquet faucibus fermentum non lacusipsum
              dolor sit amet, consectetur adipis
            </p>
            <div className="relative">
              <p className="pl-5 ml-5 border-l border-l-troo-secondary">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vestibulum velit tortor, interdum sed cursus eu, sagittis ut
                nunc. Sed vitae tellus et arcu aliquet{" "}
                <span className="text-troo-secondary">faucibus fermentum</span>{" "}
                non lacus.Lorem ipsum dolor sit amet, consectetur adipiscing
                elit. Vestibulum velit tortor, interdum sed cursus eu, sagittis
                ut nunc. Sed vitae tellus et arcu aliquet faucibus fermentum non
                lacusipsum dolor sit amet, consectetur adipis
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full">
              <img
                className="w-full"
                src={serviceDetails1}
                alt="serviceDetails1"
              />
              <img
                className="w-full"
                src={serviceDetails2}
                alt="serviceDetails2"
              />
            </div>
            <h3>Description</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Vestibulum velit tortor, interdum sed cursus eu, sagittis ut nunc.
              Sed vitae tellus et arcu aliquet faucibus fermentum non
              lacus.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Vestibulum velit tortor, interdum sed cursus eu, sagittis ut nunc.
              Sed vitae tellus et arcu aliquet faucibus fermentum non lacusipsum
              dolor sit amet, consectetur adipis
            </p>
            <p>
              Lorem ipsum dolor sit amet,{" "}
              <span className="text-troo-secondary">
                consectetur adipiscing elit
              </span>
              . Vestibulum velit tortor, interdum sed cursus eu, sagittis ut
              nunc. Sed vitae tellus et arcu aliquet faucibus fermentum non
              lacus.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Vestibulum velit tortor, interdum sed.
            </p>
            <h3>My Working Process</h3>
            <div className=" md:pl-6 flex flex-col gap-y-4">
              <div className="flex items-center gap-3">
                <div className="flex items-start">
                  <BsCheckCircle className="text-[20px] text-troo-secondary" />
                </div>
                <p>
                  Lorem ipsum dolor Vestibulum velit tortor, interdum sed cursus
                  eu, sagittis ut nunc.
                </p>
              </div>
              <div className="flex items-center gap-3">
                <div>
                  <BsCheckCircle className="w-[20px] h-[30px] text-troo-secondary" />
                </div>
                <p>
                  Interdum sed cursus eu, sagittis ut nunc. Sed vitae tellus et
                  arcu aliquet faucibus fermentum non lacus.
                </p>
              </div>
              <div className="flex items-center gap-3">
                <div>
                  <BsCheckCircle className="w-[20px] h-[30px] text-troo-secondary" />
                </div>
                <p>
                  Lorem ipsum dolor Vestibulum velit tortor, interdum sed cursus
                  eu, sagittis ut nunc. Sed vitae tellus.
                </p>
              </div>
              <div className="flex items-center gap-3">
                <div>
                  <BsCheckCircle className="w-[20px] h-[30px] text-troo-secondary" />
                </div>
                <p>Lorem ipsum dolor Vestibulum velit tortor, interdum sed .</p>
              </div>
            </div>
          </div>
          <div className="w-full md:sticky top-40 lg:w-[30%] flex flex-col items-start gap-4">
            <div className="border border-troo-hrtag  w-full">
              <h3 className="bg-troo-hrtag p-5">Other Services</h3>
              <div className="px-5 flex flex-col ">
                <p className="border-b border-b-troo-hrtag py-3">Web Development</p>
                <p className="border-b border-b-troo-hrtag py-3">Graphic Design</p>
                <p className="border-b border-b-troo-hrtag py-3">Digital Marketing</p>
                <p className="border-b border-b-troo-hrtag py-3">SEO</p>
                <p className="border-b border-b-troo-hrtag py-3">Game Development</p>
                <p className="border-b border-b-troo-hrtag py-3">Animation </p>
                <p className="border-b border-b-troo-hrtag py-3">Web Hosting </p>
                <p className="py-3">Video Editing </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetails;
