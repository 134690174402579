import React from "react";
import { Link } from "react-router-dom";
import { TeamData } from "../Data/TeamData";

const TeamComponent = () => {
  return (
    <div className="team_section bg-troo-primary py-12 lg:py-24">
      <div className="main_contaner">
        <div className="team_heading lg:flex justify-between mb-12">
          <div className="mb-5 lg:mb-0">
            <div className="sub_heading design">My Team</div>
            <h4 className="headingMini mb-6">
              My Team
            </h4>
            <h2 className="w-full lg:max-w-[550px]">
              Let’s Make Something Together
              <span className="textGradient"> Start a Conversation</span>
            </h2>
          </div>
          <div className="flex items-center lg:justify-end">
            <Link to="/my-team" className="btn-theme-secondary btn-theme-lg ">See My Team</Link>
          </div>
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8">
          {TeamData.slice(0,3).map((e, i) => (
            <div className="teamBox" key={i}>
              <img className="w-full" src={e.img} alt="team1" />
              <div className="absolute bottom-0 left-0 pl-12 pb-12 ">
                <h3>{e.name} </h3>
                <h6>{e.desg} </h6>
              </div>
              <div className="absolute top-[50%] left-[30%] flex gap-2 ">
                {e.social.map((item, i) => (
                  <div
                    className="teamicon"
                    key={i}
                  >
                    {item.icon}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeamComponent;
