import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import Service from '../../Components/Service'
import MyPortfolio from '../../Components/MyPortfolio'
import TeamComponent from '../../Components/TeamComponent'
import WorkProcess from '../../Components/WorkProcess'
import HighlightComponent from '../../Components/HighlightComponent'
import Contacts from '../../Components/Contacts'
import useDocumentTitle from '../../PageTitle'

const ServiceListPage = () => {
  useDocumentTitle("Portfolio | Service List")
  return (
    <Layout>
        <BannerGlobal/>
        <Service/>
        <MyPortfolio/>
        <TeamComponent/>
        <WorkProcess/>
        <HighlightComponent/>
        <Contacts/>
    </Layout>
  )
}

export default ServiceListPage