import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import MyPortfolio2 from '../../Components/MyPortfolio2'
import useDocumentTitle from '../../PageTitle'

const PortfolioPage = () => {
  useDocumentTitle("Portfolio | Portfolio")
  return (
    <Layout>
        <BannerGlobal/>
        <MyPortfolio2/>
    </Layout>
  )
}

export default PortfolioPage