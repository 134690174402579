import React from 'react'
import Layout from '../Components/Layout'
import BannerGlobal from '../Components/BannerGlobal'
import About from '../Components/About'
import WorkJourney from '../Components/WorkJourney'
import ClientReview from '../Components/ClientReview'
import HighlightComponent from '../Components/HighlightComponent'
import Contacts from '../Components/Contacts'
import useDocumentTitle from '../PageTitle'

const AboutPage = () => {
  useDocumentTitle("Portfolio | About Me")
  return (
    <Layout>
        <BannerGlobal/>
        <About/>
        <WorkJourney/>
        <ClientReview/>
        <HighlightComponent/>
        <Contacts/>
    </Layout>
  )
}

export default AboutPage