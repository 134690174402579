import client1 from "../Images/clinet_img1.png";
import client2 from "../Images/clinet_img2.png";
import client3 from "../Images/clinet_img3.png";
import { BsStar } from "react-icons/bs";

export const ClientData = [
  {
    id: 1,
    img: client1,
    name: "Luis O’reain",
    sub: "UpWork Client",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make ",
    star: <><BsStar /><BsStar /><BsStar /><BsStar /><BsStar /></>,
  },
  {
    id: 2,
    img: client2,
    name: "Rajani Sharma",
    sub: "Local  Client",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make ",
    star: <><BsStar /><BsStar /><BsStar /><BsStar /><BsStar /></>,
  },
  {
    id: 3,
    img: client3,
    name: "Denial Sanda",
    sub: "Local  Client",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make ",
    star: <><BsStar /><BsStar /><BsStar /><BsStar /><BsStar /></>,
  },
  {
    id: 4,
    img: client1,
    name: "Luis O’reain",
    sub: "UpWork Client",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make ",
    star: <><BsStar /><BsStar /><BsStar /><BsStar /><BsStar /></>,
  },
  {
    id: 5,
    img: client2,
    name: "Rajani Sharma",
    sub: "Local  Client",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make ",
    star: <><BsStar /><BsStar /><BsStar /><BsStar /><BsStar /></>,
  },
  {
    id: 6,
    img: client3,
    name: "Denial Sanda",
    sub: "Local  Client",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make ",
    star: <><BsStar /><BsStar /><BsStar /><BsStar /><BsStar /></>,
  },
  {
    id: 7,
    img: client1,
    name: "Luis O’reain",
    sub: "UpWork Client",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make ",
    star: <><BsStar /><BsStar /><BsStar /><BsStar /><BsStar /></>,
  },
  {
    id: 8,
    img: client2,
    name: "Rajani Sharma",
    sub: "Local  Client",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make ",
    star: <><BsStar /><BsStar /><BsStar /><BsStar /><BsStar /></>,
  },
  {
    id: 9,
    img: client3,
    name: "Denial Sanda",
    sub: "Local  Client",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make ",
    star: <><BsStar /><BsStar /><BsStar /><BsStar /><BsStar /></>,
  },
  {
    id: 10,
    img: client1,
    name: "Luis O’reain",
    sub: "UpWork Client",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make ",
    star: <><BsStar /><BsStar /><BsStar /><BsStar /><BsStar /></>,
  },
  {
    id: 11,
    img: client2,
    name: "Rajani Sharma",
    sub: "Local  Client",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make ",
    star: <><BsStar /><BsStar /><BsStar /><BsStar /><BsStar /></>,
  },
  {
    id: 12,
    img: client3,
    name: "Denial Sanda",
    sub: "Local  Client",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make ",
    star: <><BsStar /><BsStar /><BsStar /><BsStar /><BsStar /></>,
  },
];
