import React from "react";
import { ClientData } from "../Data/ClientData";

const ClientReview2 = () => {
  return (
    <div className="reviewPage2 bg-troo-deepblack py-12 lg:py-24">
      <div className="main_contaner">
        <div className="reviewHeading text-center">
          <h4 className="headingMini">
            Clients Review
          </h4>
          <h2 className="py-7 max-w-[500px] m-auto">
            What My Loved
            <span className="text-troo-secondary">Clients Say</span> About Me.
          </h2>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {ClientData.map((e, i) => (
              <div className="border-b border-troo-hrtag pb-5">
                <div className="service_card lg:p-5">
                  <div className="md:flex justify-between pb-5">
                    <div className="flex items-center gap-3">
                      <img className="w-20 h-20" src={e.img} alt="client1" />
                      <div>
                        <h3 className="text-troo-secondary">{e.name}</h3>
                        <p>{e.sub} </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-1 md:justify-end text-troo-secondary mt-3 md:mt-0">
                      {e.star}
                    </div>
                  </div>
                  <p>{e.para}</p>
                </div>
              </div>
            ))}

        </div>
      </div>
    </div>
  );
};

export default ClientReview2;
