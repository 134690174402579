import React from "react";
import brand1 from "../Images/brand1.png";
import brand2 from "../Images/brand2.png";
import brand3 from "../Images/brand3.png";
import brand4 from "../Images/brand4.png";
import brand5 from "../Images/brand5.png";
import brand6 from "../Images/brand6.png";
import brand7 from "../Images/brand7.png";
import brand8 from "../Images/brand8.png";

const BrandLogo = () => {
  return (
    <div className="BrandLogo bg-troo-deepblack">
      <div className="main_contaner py-12 lg:py-20">
        <div className="text-center">
          <div className="sub_heading design">Brands</div>
          <h4 className="headingMini">
            My Clients
          </h4>
          <h2>
            <span className="textGradient">We’ve built</span> solutions
            for...
          </h2>
        </div>
        <div className="grid grid-cols-1  gap-8 md:gap-y-12 lg:gap-y-8 py-12 md:grid-cols-2 lg:grid-cols-4">
          <div className="service_card bg-troo-primary">
            <img className="w-full" src={brand1} alt="brand1" />
          </div>
          <div className="service_card bg-troo-primary">
            <img className="w-full" src={brand2} alt="brand2" />
          </div>
          <div className="service_card bg-troo-primary">
            <img className="w-full" src={brand3} alt="brand3" />
          </div>
          <div className="service_card bg-troo-primary">
            <img className="w-full" src={brand4} alt="brand4" />
          </div>
          <div className="service_card bg-troo-primary">
            <img className="w-full" src={brand5} alt="brand5" />
          </div>
          <div className="service_card bg-troo-primary">
            <img className="w-full" src={brand6} alt="brand6" />
          </div>
          <div className="service_card bg-troo-primary" >
            <img className="w-full" src={brand7} alt="brand7" />
          </div>
          <div className="service_card bg-troo-primary">
            <img className="w-full" src={brand8} alt="brand8" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandLogo;
