import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { HiOutlineChevronDown } from "react-icons/hi";

const NavMenu = ({ item }) => {
  const [showcat, setShowCat] = useState(false);
  const location = useLocation();
    let arr = location.pathname?.split("/");
    let lastvalue = arr[arr.length - 1].trim().replace(/-/g, " ");
    let filt = item.subNav?.find((s) => s.subTitle === lastvalue);
    console.log(item.title);
    console.log(arr);
  return (
    <li className="nav_item">
      {!item?.subNav ? (
        <Link to={item.path} className={item.path === location.pathname ?"nav_link active " : "nav_link"} >{item.title}</Link>
      ) : (
     
        <div class="dropdown inline-block relative">
          <Link to={item.path}
            className={item.title === lastvalue || filt ?"nav_link active " : "nav_link"}
            onMouseEnter={() => setShowCat(true)}
          >
            {item.title}
            <HiOutlineChevronDown className="ml-1" />
          </Link>
          {showcat && (
            <ul className="dropdown-menu w-48 absolute hidden text-troo-deepblack  bg-gray-100 flex-col p-1">
              {item.subNav.map((e, i) => {
                return (
                  <li className={`text-left hover:text-troo-white hover:bg-zinc-800 p-3 ${e.subTitle===lastvalue ? "active" : " "}`}>
                    <Link to={e.path}>{e.subTitle} </Link>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      )}
    </li>
  );
};

export default NavMenu;
