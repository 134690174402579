import team1 from "../Images/team1.png";
import team2 from "../Images/team2.png";
import team3 from "../Images/team3.png";
import team4 from "../Images/team4.png";
import team5 from "../Images/team5.png";
import team6 from "../Images/team6.png";
import team7 from "../Images/team7.png";
import team8 from "../Images/team8.png";
import team9 from "../Images/team9.png";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";

export const TeamData = [
  {
    id: 1,
    img: team1,
    name: "Samantha Rooth",
    desg: "UI/UX Designer",
    social: [
      {
        icon: <FaFacebookF />,
        path: "",
      },
      {
        icon: <FaInstagram />,
        path: "",
      },
      {
        icon: <FaTwitter />,
        path: "",
      },
      {
        icon: <FaLinkedinIn />,
        path: "",
      },
    ],
  },
  {
    id: 2,
    img: team2,
    name: "Charlote Orange",
    desg: "Vieo Editer",
    social: [
      {
        icon: <FaFacebookF />,
        path: "",
      },
      {
        icon: <FaInstagram />,
        path: "",
      },
      {
        icon: <FaTwitter />,
        path: "",
      },
      {
        icon: <FaLinkedinIn />,
        path: "",
      },
    ],
  },
  {
    id: 3,
    img: team3,
    name: "Wiliams jems",
    desg: "Web Developer",
    social: [
      {
        icon: <FaFacebookF />,
        path: "",
      },
      {
        icon: <FaInstagram />,
        path: "",
      },
      {
        icon: <FaTwitter />,
        path: "",
      },
      {
        icon: <FaLinkedinIn />,
        path: "",
      },
    ],
  },
  {
    id: 4,
    img: team4,
    name: "Abby Normal",
    desg: "Game Developer",
    social: [
      {
        icon: <FaFacebookF />,
        path: "",
      },
      {
        icon: <FaInstagram />,
        path: "",
      },
      {
        icon: <FaTwitter />,
        path: "",
      },
      {
        icon: <FaLinkedinIn />,
        path: "",
      },
    ],
  },
  {
    id: 5,
    img: team5,
    name: "Saul Goodmate",
    desg: "Digital Marketin Expert",
    social: [
      {
        icon: <FaFacebookF />,
        path: "",
      },
      {
        icon: <FaInstagram />,
        path: "",
      },
      {
        icon: <FaTwitter />,
        path: "",
      },
      {
        icon: <FaLinkedinIn />,
        path: "",
      },
    ],
  },
  {
    id: 6,
    img: team6,
    name: "Jack Aranda",
    desg: "Python Developer",
    social: [
      {
        icon: <FaFacebookF />,
        path: "",
      },
      {
        icon: <FaInstagram />,
        path: "",
      },
      {
        icon: <FaTwitter />,
        path: "",
      },
      {
        icon: <FaLinkedinIn />,
        path: "",
      },
    ],
  },
  {
    id: 7,
    img: team7,
    name: "Fran G. Pani",
    desg: "Angular Expert",
    social: [
      {
        icon: <FaFacebookF />,
        path: "",
      },
      {
        icon: <FaInstagram />,
        path: "",
      },
      {
        icon: <FaTwitter />,
        path: "",
      },
      {
        icon: <FaLinkedinIn />,
        path: "",
      },
    ],
  },
  {
    id: 8,
    img: team8,
    name: "Rose Bush",
    desg: "Shopify Expert",
    social: [
      {
        icon: <FaFacebookF />,
        path: "",
      },
      {
        icon: <FaInstagram />,
        path: "",
      },
      {
        icon: <FaTwitter />,
        path: "",
      },
      {
        icon: <FaLinkedinIn />,
        path: "",
      },
    ],
  },
  {
    id: 9,
    img: team9,
    name: "Sara Logan",
    desg: "PHP  Developer",
    social: [
      {
        icon: <FaFacebookF />,
        path: "",
      },
      {
        icon: <FaInstagram />,
        path: "",
      },
      {
        icon: <FaTwitter />,
        path: "",
      },
      {
        icon: <FaLinkedinIn />,
        path: "",
      },
    ],
  },
];
