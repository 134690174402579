import React from "react";
import aboutImg from "../Images/aboutImg.png";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <div className="bg-troo-deepblack about">
      <div className="main_contaner">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div>
            <img src={aboutImg} alt="aboutImg" />
          </div>
          <div className="py-12 lg:py-24 md:ps-8">
            <div className="sub_heading design">ABOUT ME</div>
            <h4 className="headingMini">
              About Me
            </h4>
            <h2 className="pb-8">
              <span className="textGradient">My mission</span> is design
              develop the best Web sites around.
            </h2>
            <h4 className="mb-6">
              Lorem Ipsum is simply dummy text of and it the printing and
              typesetting industry.
            </h4>
            <h3 className="mb-6">My Goal</h3>
            <p className="pb-12">
              Lorem Ipsum is simply dummy text of and it the printing and
              typesetting industry. Lorem Ipsum is simply dummy text of and it
              the printing. Lorem Ipsum is simply dummy text of and it the
              printing and typesetting industry.
            </p>
            <Link className="btn-theme-secondary btn-theme-lg " to="/about-me">Download My Resume</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
