import React from "react";
import { Link } from "react-router-dom";
import boll from "../Images/boll.png";
import beBoll from "../Images/beBoll.png";

const ComingSoon = () => {
  return (
    <div className="commingsoon bg-troo-primary py-12 md:py-24 lg:py-48">
      <div className="main_contaner text-left">
        <div className="hello mb-5">Hello There!</div>
        <div className="comingHeading mb-14">
          Something Fresh Coming Soon. I Am Working on It.
        </div>
        <h4 className="mb-6">Get Notified When I launch.</h4>
        <form className="w-full flex" >
          <input
            type="email"
            placeholder="Email"
            className="px-2 md:px-7 w-[70%] md:w-[500px] py-2 md:py-5 bg-transparent border rounded border-troo-borderclr"
          />
          <Link className="w-[30%] md:w-auto box-border px-2 text-center md:px-7 py-2 md:py-5 bg-gradient-to-b from-troo-secondary to-troo-tertiary">NOTIFY ME</Link>
        </form>
        <div className="flex flex-col lg:flex-row items-start lg:items-center whitespace-nowrap gap-6 mt-12">
              <div className="flex items-center justify-between gap-2">
                <img src={boll} alt="boll" className="w-14 h-14 rounded-full" />
                <div>
                  <h4>12k</h4>
                  <p>Dribbble Followers</p>
                </div>
              </div>
              <div className="flex items-center whitespace-nowrap justify-between gap-2">
                <img
                  src={beBoll}
                  alt="boll"
                  className="w-14 h-14 rounded-full"
                />
                <div>
                  <h4>12k</h4>
                  <p>Behance Followers</p>
                </div>
              </div>
            </div>
      </div>
    </div>
  );
};

export default ComingSoon;
