import React from "react";
import { LuMailOpen } from "react-icons/lu";
import { BiPhone } from "react-icons/bi";
import { HiOutlineLocationMarker } from "react-icons/hi";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const Contacts = () => {
  return (
    <div className="contact bg-troo-deepblack py-12 lg:py-24">
      <div className="main_contaner">
        <div className="lg:flex justify-between gap-8">
          <div className="lg:w-[40%]">
            <div className="contactText">
              <div className="sub_heading design">Contact Me</div>
              <h4 className="headingMini">
                Need a Designer / Developer
              </h4>
              <h2 className="py-8">
                <span className="textGradient"> Let’s </span> Work
                Together
              </h2>
            </div>
            <div className="flex flex-col gap-y-5">
              <div className="service_card flex gap-4 p-3 md:p-5">
                <div className=" text-troo-white border border-troo-secondary rounded-full flex justify-center items-center w-[50px] h-[50px]">
                  <LuMailOpen />
                </div>
                <div>
                  <h3>Email Me</h3>
                  <p>alexajems125@email.com</p>
                </div>
              </div>
              <div className="service_card flex gap-4 p-3 md:p-5">
                <div className=" text-troo-white border border-troo-secondary rounded-full flex justify-center items-center w-[50px] h-[50px]">
                  <BiPhone />
                </div>
                <div>
                  <h3>Call Me</h3>
                  <p>+44 123 456 7890</p>
                </div>
              </div>
              <div className="service_card flex gap-4 p-3 md:p-5">
                <div className=" text-troo-white border border-troo-secondary rounded-full flex justify-center items-center w-[50px] h-[50px]">
                  <HiOutlineLocationMarker />
                </div>
                <div>
                  <h3>Address</h3>
                  <p className="max-w-[200px]">Warnwe Park Streetperrine, FL 33157 New York City</p>
                </div>
              </div>
            </div>
            <div className="mt-8">
              <h4>Follow Me on Social Media</h4>
              <div className="flex gap-3 mt-6">
                <div className="teamicon">
                  <FaFacebookF />
                </div>
                <div className="teamicon">
                  <FaInstagram />
                </div>
                <div className="teamicon">
                  <FaLinkedinIn />
                </div>
                <div className="teamicon">
                  <FaTwitter />
                </div>
              </div>
            </div>
          </div>

          <div className="lg:w-[60%] pt-8">
            <h3>Estimate your Project?</h3>
            <form className="mt-12">
              <div className="flex flex-col gap-y-8">
                <div className="grid w-full grid-cols-1 md:grid-cols-2 gap-4 md:gap-7">
                  <input
                    type="text"
                    placeholder="Full Name"
                    className="p-5 bg-transparent border border-troo-hrtag rounded"
                  />
                  <input
                    type="number"
                    placeholder="Phone"
                    className="p-5 bg-transparent border rounded border-troo-hrtag"
                  />
                  <input
                    type="email"
                    placeholder="Email"
                    className="p-5 bg-transparent border rounded border-troo-hrtag"
                  />
                  <input
                    type="text"
                    placeholder="Subject"
                    className="p-5 bg-transparent border rounded border-troo-hrtag"
                  />
                </div>
                <div className="w-full">
                  <textarea
                    placeholder="White something to me"
                    className="p-5 bg-transparent border rounded border-troo-hrtag w-full"
                    rows={8}
                  />
                </div>
                <div>
                    <Link className="btn-theme-secondary btn-theme-lg" > Send Message</Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
