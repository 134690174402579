import React from "react";
import { Link } from "react-router-dom";
import errror from "../Images/404.png";

const Error404 = () => {
  return (
    <div className="Error404 bg-troo-primary py-12 lg:py-24">
      <div className="text-center px-6 xl:px-7">
        <img className="m-auto max-w-[511px] w-full" src={errror} alt="error" />
        <div className="h1 text-troo-white">Page Not Found</div>
        <h3 className="mb-6">
          Oops, We Are Sorry, The Page You Requested Can Not Be Found.
        </h3>
        <Link className="btn-theme-secondary btn-theme-lg " to="/">
          Go To Homepage
        </Link>
      </div>
    </div>
  );
};

export default Error404;
