import React from "react";
import { FaRegUser } from "react-icons/fa";
import { PiCalendarDuotone, PiTelevisionSimpleBold } from "react-icons/pi";
import blogDetails from "../Images/blogDetails.png";
import blogDetails1 from "../Images/blogDetails1.png";
import blogDetails2 from "../Images/blogDetails2.png";
import blogDetails3 from "../Images/blogDetails3.png";
import { LuQuote } from "react-icons/lu";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import smallblog1 from "../Images/smallBlogs1.png";
import smallblog2 from "../Images/smallBlogs2.png";
import smallBlogs3 from "../Images/smallBlogs3.png";
import smallBlogs4 from "../Images/smallBlogs4.png";

const BlogDetails = () => {
  return (
    <div className="BlogDetails w-full bg-troo-deepblack py-12 lg:py-24">
      <div className="main_contaner">
        <div className="flex flex-col lg:flex-row h-full items-start gap-6">
          <div className="w-full lg:w-[70%] flex flex-col items-start gap-5">
            <h2>What Developers Need from UX Research</h2>
            <div className="flex flex-col md:flex-row md:justify-between md:items-center w-full gap-4">
              <div className="flex md:items-center gap-4">
                <FaRegUser className="text-troo-secondary w-5 h-5" />
                <p>Posted by: Admin</p>
              </div>
              <div className="flex md:items-center gap-4">
                <PiCalendarDuotone className="text-troo-secondary w-5 h-5" />
                <p>Post Date: 25 Oct, 2022</p>
              </div>
              <div className="flex md:items-center gap-4">
                <PiTelevisionSimpleBold className="text-troo-secondary w-5 h-5" />
                <p>Category: UI/UX, Design, Web Development</p>
              </div>
            </div>
            <img className="w-full" src={blogDetails} alt="blogDetails" />
            <h3>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Vestibulum velit tortor, interdum sed cursus.
            </h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Vestibulum velit tortor, interdum sed cursus eu, sagittis ut nunc.
              Sed vitae tellus et arcu aliquet faucibus fermentum non
              lacus.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Vestibulum velit tortor, interdum sed cursus eu, sagittis ut nunc.
              Sed vitae tellus et arcu aliquet faucibus fermentum non lacusipsum
              dolor sit amet, consectetur adipis
            </p>
            <p>
              Lorem ipsum dolor sit amet,{" "}
              <span className="text-troo-secondary">
                consectetur adipiscing
              </span>{" "}
              elit. Vestibulum velit tortor, interdum sed cursus eu, sagittis ut
              nunc. Sed vitae tellus et arcu aliquet faucibus fermentum non
              lacus.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Vestibulum velit tortor, interdum sed.
            </p>
            <div className="p-6 bg-troo-hrtag">
              <LuQuote className="text-troo-white mb-3 w-7 h-7" />
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vestibulum velit tortor, interdum sed cursus eu, sagittis ut
                nunc. Sed vitae tellus et arcu aliquet faucibus fermentum non
                lacus.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                VVestibulum velit torto
              </p>
            </div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Vestibulum velit tortor, interdum sed cursus eu, sagittis ut nunc.
              Sed vitae tellus et arcu aliquet faucibus fermentum non
              lacus.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Vestibulum velit tortor.
            </p>
            <div className="grid w-full grid-cols-1 lg:grid-cols-2 gap-5">
              <div>
                <img className="w-full" src={blogDetails1} alt="blogdetails1" />
              </div>
              <div className="flex flex-col gap-y-5">
                <img className="w-full" src={blogDetails2} alt="blogDetails2" />
                <img className="w-full" src={blogDetails3} alt="blogDetails3" />
              </div>
            </div>
            <div className="p-6 bg-troo-hrtag flex items-center gap-3 w-full">
              <p>Share : </p>
              <Link className="teamicon">
                <FaFacebookF />
              </Link>
              <Link className="teamicon">
                <FaLinkedinIn />
              </Link>
              <Link className="teamicon">
                <FaInstagram />
              </Link>
              <Link className="teamicon">
                <FaTwitter />
              </Link>
            </div>
          </div>
          <div className="w-full md:sticky top-40 lg:w-[30%] flex flex-col items-start gap-7">
            <div className="border border-troo-hrtag  w-full">
              <h3 className="bg-troo-hrtag p-3 md:p-5">Other Services</h3>
              <div className="px-5 flex flex-col ">
                <div className="border-b border-b-troo-hrtag py-3 flex justify-between">
                  <p>Web Development</p>
                  <p>(10)</p>
                </div>
                <div className="border-b border-b-troo-hrtag py-3 flex justify-between">
                  <p>Graphic Design</p>
                  <p>(05)</p>
                </div>
                <div className="border-b border-b-troo-hrtag py-3 flex justify-between">
                  <p>Digital Marketing</p>
                  <p>(08)</p>
                </div>
                <div className="border-b border-b-troo-hrtag py-3 flex justify-between">
                  <p>SEO</p>
                  <p>(01)</p>
                </div>
                <div className="border-b border-b-troo-hrtag py-3 flex justify-between">
                  <p>Game Development</p>
                  <p>(03)</p>
                </div>
                <div className="border-b border-b-troo-hrtag py-3 flex justify-between">
                  <p>Animation</p>
                  <p>(04)</p>{" "}
                </div>
                <div className="border-b border-b-troo-hrtag py-3 flex justify-between">
                  <p>Web Hosting</p>
                  <p>(09)</p>{" "}
                </div>
                <div className="py-3 flex justify-between">
                  <p>Video Editing</p>
                  <p>(01)</p>{" "}
                </div>
              </div>
            </div>
            <div className="border border-troo-hrtag  w-full">
              <h3 className="bg-troo-hrtag p-3 md:p-5">Tags</h3>
              <div className="p-3 md:p-5 flex flex-wrap gap-6 w-full ">
                <p>Web Development</p>
                <p>Graphic Design</p>
                <p>SEO</p>
                <p>Digital Marketing</p>
                <p>Game Development</p>
                <p>Animation </p>
                <p>Web Hosting</p>
                <p>Video Editing</p>
              </div>
            </div>
            <div className="border border-troo-hrtag  w-full">
              <h3 className="bg-troo-hrtag p-3 md:p-6">Recent Post</h3>
              <div className="p-3 md:p-6 flex flex-col gap-y-5">
                <div className="flex gap-4">
                  <img className="rounded" src={smallblog1} alt="smallblog1" />
                  <div>
                    <h6 className="mb-3">Date: 20 Oct, 2022</h6>
                    <p>Ideas from Developers on Handling UX Feedback</p>
                  </div>
                </div>
                <div className="flex gap-4">
                  <img className="rounded" src={smallblog2} alt="smallblog1" />
                  <div>
                    <h6 className="mb-3">Date: 20 Oct, 2022</h6>
                    <p>Ideas from Developers on Handling UX Feedback</p>
                  </div>
                </div>
                <div className="flex gap-4">
                  <img className="rounded" src={smallBlogs3} alt="smallblog1" />
                  <div>
                    <h6 className="mb-3">Date: 20 Oct, 2022</h6>
                    <p>Ideas from Developers on Handling UX Feedback</p>
                  </div>
                </div>
                <div className="flex gap-4">
                  <img className="rounded" src={smallBlogs4} alt="smallblog1" />
                  <div>
                    <h6 className="mb-3">Date: 20 Oct, 2022</h6>
                    <p>Ideas from Developers on Handling UX Feedback</p>
                  </div>
                </div>
                <div className="text-center">
                  <Link className="btn-theme-secondary btn-theme-lg w-full">SEE All BLOGS</Link>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
