import React from "react";
import { Link, createSearchParams } from "react-router-dom";
import { BlogData } from "../Data/BlogData";

const Blogs = () => {
  return (
    <div className="Blogs  bg-troo-primary py-12">
      <div className="main_contaner">
        <div className="blogText text-center">
          <div className="sub_heading design">My Blogs</div>
          <h4 className="headingMini">Articles & News</h4>
          <h2>
            <span className="textGradient">My Recent</span> Some Project / Work.
          </h2>
        </div>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mt-12">
          <div className="flex flex-col gap-y-7">
            {BlogData.slice(0, 2).map((e, i) => (
              <Link
                to={`/blogs/blog-details?${createSearchParams({ id: e.id })}`}
                className="group transition-all relative overflow-hidden"
                key={i}
              >
                <img
                  className="rounded-md transition ease-in-out duration-500 group-hover:scale-125"
                  src={e.img}
                  alt="blog1"
                />
                <div className="blogTextcard absolute bottom-0 left-0 pl-5 pb-5">
                  <h6 className="p-2 bg-troo-primary inline-block rounded mb-2 btn-theme">
                    {e.test}
                  </h6>
                  <h3 className="">{e.name}</h3>
                </div>
              </Link>
            ))}
          </div>
          {BlogData.slice(2, 3).map((e, i) => (
            <Link
              to={`/blogs/blog-details?${createSearchParams({ id: e.id })}`}
              className="group transition-all relative overflow-hidden"
              key={i}
            >
              <img
                className="rounded-md transition ease-in-out duration-500 group-hover:scale-125"
                src={e.img}
                alt="blog3"
              />
              <div className="absolute bottom-0 left-0 pl-5 md:pl-5 pb-5 md:pb-6">
                <h6 className="p-2 bg-troo-primary inline-block rounded mb-2 btn-theme">
                  {e.test}
                </h6>
                <h3 className="">{e.name}</h3>
              </div>
            </Link>
          ))}
          <div className="flex flex-col gap-y-7">
            {BlogData.slice(3, 5).map((e, i) => (
              <Link
                to={`/blogs/blog-details?${createSearchParams({ id: e.id })}`}
                className="group transition-all relative overflow-hidden"
                key={i}
              >
                <img
                  className="rounded-md transition ease-in-out duration-500 group-hover:scale-125"
                  src={e.img}
                  alt="blog1"
                />
                <div className="blogTextcard absolute bottom-0 left-0 pl-5 pb-5">
                  <h6 className="p-2 bg-troo-primary inline-block rounded mb-2 btn-theme">
                    {e.test}
                  </h6>
                  <h3 className="">{e.name}</h3>
                </div>
              </Link>
            ))}
          </div>
        </div>
        <div className="text-center py-12">
          <Link className="btn-theme-secondary btn-theme-lg">
            See All My Blogs
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
