import blog1 from "../Images/blog1.png";
import blog2 from "../Images/blog2.png";
import blog3 from "../Images/blog3.png";
import blog4 from "../Images/blog4.png";
import blog5 from "../Images/blog5.png";
import blog6 from "../Images/blog6.png";
import blog7 from "../Images/blog7.png";
import blog8 from "../Images/blog8.png";
import blog9 from "../Images/blog9.png";
import blog10 from "../Images/blog10.png";

export const BlogData = [
  {
    id: 1,
    img : blog1,
    test : "UI/UX Design",
    name : "What Developers Need from UX Research"
  },
  {
    id: 2,
    img : blog2,
    test : "Marketing",
    name : "The Best UX Research Methods in a Pinch"
  },
  {
    id: 3,
    img : blog3,
    test : "Design",
    name : "Fast and Cheap Ways to Find UX Research Participants"
  },
  {
    id: 4,
    img : blog4,
    test : "Game News",
    name : "7 Takeaways from the 2020 Design Tools Survey"
  },
  {
    id: 5,
    img : blog5,
    test : "Security",
    name : "What No One Explains About the Design Process"
  },
  {
    id: 6,
    img : blog6,
    test : "Design",
    name : "How to Maximize the User Research You're Already Doing"
  },
  {
    id: 7,
    img : blog7,
    test : "Daily Life",
    name : "Usability Testing in 4 Simplified Steps"
  },
  {
    id: 8,
    img : blog8,
    test : "Marketing",
    name : "17 Tools That Will Streamline Your UX Research"
  },
  {
    id: 9,
    img : blog9,
    test : "Security",
    name : "The Psychology of User Decisions"
  },
  {
    id: 10,
    img : blog10,
    test : "Game News",
    name : "Running an Effective Design Kickoff Meeting"
  }
];
