import React from "react";
import { Link, createSearchParams } from "react-router-dom";
import CounterUp from "./CounterUp";
import { ServiceData } from "../Data/ServiceData";

const Service = () => {
  return (
    <div className="bg-troo-primary seviceBG py-12">
      <div className="main_contaner">
        <div className="md:flex justify-between ">
          <div className="service_heading">
            <div className="sub_heading design">Services</div>
            <h4 className="headingMini">
              My Services
            </h4>
            <h2 className="pb-8">
              <span className="textGradient">What I Provide</span> To My
              Clients
            </h2>
          </div>
          <div className="flex items-center md:justify-end ">
            <Link to="/my-services" className="btn-theme-secondary btn-theme-lg">See All My Services</Link>
          </div>
        </div>
        <div className="service_cards_container">
          {ServiceData.slice(0,4).map((e, i) => (
            <div
              className=" gap-5 service_card border-b border-troo-hrtag md:flex justify-between items-center py-6 lg:py-12 pl-0 lg:pl-4 "
              key={i}
            >
              <div className="flex gap-6 lg:gap-8 xl:gap-12 items-center w-full md:w-[34%]">
                <div className=" text-troo-white border border-troo-secondary rounded-full flex justify-center items-center w-[50px] h-[50px] ">
                  {e.id}
                </div>
                <h3>{e.name} </h3>
              </div>
              <div className="md:flex justify-between items-center w-full md:w-[66%]  gap-4 xl:gap-8">
                <p className="max-w-[100%] md:max-w-[280px] xl:max-w-[550px]  py-5 md:py-0">
                  {e.para}
                </p>
                <div className="mr-14">
                  <Link to={`/service-grid/service-details?${createSearchParams({id:e.id})}`} className="viewDetails">
                    View Detail
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
        <CounterUp />
      </div>
    </div>
  );
};

export default Service;
