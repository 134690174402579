import React from "react";
import { AiOutlineCalendar } from "react-icons/ai";

const WorkJourney = () => {
  return (
    <div class=" bg-troo-primary py-6  sm:py-12 w-full workJourney">
      <div className="main_contaner">
        <div className="text-center">
            <div className="sub_heading design">
            My Work Journey
            </div>
            <h4 className="mb-5 headingMini">
            My Experience
            </h4>
            <h2 className="pb-8  lg:max-w-[450px] m-auto">
              <span className="text-troo-secondary"> When I let go of what I am,</span> I become what I might be
            </h2>
        </div>
        <div class="py-3 sm:mx-auto px-2 sm:px-0 w-full">
          <div class="relative ">
            <div class="block w-[1px] bg-troo-white  absolute h-full md:left-1/2 transform -translate-x-1/2"></div>
            <div class="mt-6 sm:mt-0 sm:mb-12">
              <div class="flex flex-row items-center">
                <div class="flex flex-col-reverse md:flex-row justify-start w-full mx-auto items-start md:items-center">
                  <div class="w-full md:w-1/2 md:pr-8">
                    <div class="p-4 service_card text-right">
                      <h4 className="text-troo-white mb-5 ">
                        Web Designer - Eligent
                      </h4>
                      <p className="text-troo-stateTertiary">
                        Lorem ipsum dolor sit amet, consectetuer adipiscing
                        elit, sed diam nonummy nibh euismod and tincidunt ut
                        laoreet dummy.
                      </p>
                    </div>
                  </div>
                  <div class="md:pl-8 ml-4 md:ml-0 ">
                    <div class="dateBoxright text-troo-white hover:text-troo-secondary bg-troo-primary flex items-center gap-2 border border-troo-hrtag hover:border-troo-secondary px-4 py-2 rounded">
                      <AiOutlineCalendar />
                      <p className="hover:text-troo-secondary"> 2020 - 2022</p>
                    </div>
                  </div>
                </div>
                <div class="rounded-full bg-troo-white w-3 h-3 outline outline-1 outline-troo-white outline-offset-4 absolute md:left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center"></div>
              </div>
            </div>

            <div class="mt-6 sm:mt-0 sm:mb-12">
              <div class="flex flex-col sm:flex-row sm:items-center items-start justify-center sm:justify-start">
                <div class="flex flex-col md:flex-row justify-start md:justify-end w-full mx-auto items-start md:items-center">
                  <div class="md:pr-8 md:pl-8 ml-4 md:ml-0">
                    <div class=" dateBoxleft bg-troo-primary text-troo-white flex items-center gap-2 border border-troo-hrtag hover:border-troo-secondary px-4 py-2 rounded">
                      <AiOutlineCalendar />
                      <p className="hover:text-troo-secondary"> 2017 - 2020</p>
                    </div>
                  </div>
                  <div class="w-full md:w-1/2 md:pl-8">
                    <div class="p-4 service_card">
                      <h4 className="text-troo-white mb-5">
                        Web Designer - Eligent
                      </h4>
                      <p className="text-troo-stateTertiary">
                        There are many variations of passages of Lorem Ipsum
                        available, but the majority have suffered alteration in
                        some form, by injected humour, or randomised words which
                        don't look
                      </p>
                    </div>
                  </div>
                </div>
                <div class="rounded-full bg-troo-white w-3 h-3 outline outline-1 outline-troo-white outline-offset-4 absolute md:left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center"></div>
              </div>
            </div>

            <div class="mt-6 sm:mt-0 sm:mb-12">
              <div class="flex flex-row items-center">
                <div class="flex flex-col-reverse md:flex-row justify-start w-full mx-auto items-start md:items-center">
                  <div class="w-full md:w-1/2 md:pr-8">
                    <div class="p-4 service_card text-right">
                      <h4 className="text-troo-white mb-5">
                        Web Designer - Eligent
                      </h4>
                      <p className="text-troo-stateTertiary">
                        There are many variations of passages of Lorem Ipsum
                        available, but the majority have suffered alteration in
                        some form, by injected humour, or randomised words which
                        don't look
                      </p>
                    </div>
                  </div>
                  <div class="md:pl-8 ml-4 md:ml-0">
                    <div class="dateBoxright text-troo-white hover:text-troo-secondary bg-troo-primary flex items-center gap-2 border border-troo-hrtag hover:border-troo-secondary px-4 py-2 rounded">
                      <AiOutlineCalendar />
                      <p className="hover:text-troo-secondary"> 2017 - 2015</p>
                    </div>
                  </div>
                </div>
                <div class="rounded-full bg-troo-white w-3 h-3 outline outline-1 outline-troo-white outline-offset-4 absolute md:left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center"></div>
              </div>
            </div>

            <div class="mt-6 sm:mt-0 sm:mb-12">
              <div class="flex flex-col sm:flex-row sm:items-center items-start justify-center sm:justify-start">
                <div class="flex flex-col md:flex-row justify-start md:justify-end w-full mx-auto items-start md:items-center">
                  <div class="md:pr-8 md:pl-8 ml-4 md:ml-0">
                    <div class=" dateBoxleft bg-troo-primary text-troo-white flex items-center gap-2 border border-troo-hrtag hover:border-troo-secondary px-4 py-2 rounded">
                      <AiOutlineCalendar />
                      <p className="hover:text-troo-secondary"> 2011 - 2015</p>
                    </div>
                  </div>
                  <div class="w-full md:w-1/2 md:pl-8">
                    <div class="p-4 service_card">
                      <h4 className="text-troo-white mb-5">
                        It is a long established fact that a reader
                      </h4>
                      <p className="text-troo-stateTertiary">
                        There are many variations of passages of Lorem Ipsum
                        available, but the majority have suffered alteration in
                        some form, by injected humour, or randomised words which
                        don't look
                      </p>
                    </div>
                  </div>
                </div>
                <div class="rounded-full bg-troo-white w-3 h-3 outline outline-1 outline-troo-white outline-offset-4 absolute md:left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkJourney;
