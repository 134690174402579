export const FaqsData = [
    {
        id :1,
        name : "How much will I get paid?",
        para : "It is a long established fact that a the reader will be distracted by the readable content of a page when looking its layout. The point of using Lorem Ipsum is that."
    },
    {
        id :2,
        name : "Does Toptal take a cut of my earnings?",
        para : "It is a long established fact that a the reader will be distracted by the readable content of a page when looking its layout. The point of using Lorem Ipsum is that."
    },
    {
        id :3,
        name : "Does Toptal provide visa sponsorship?",
        para : "It is a long established fact that a the reader will be distracted by the readable content of a page when looking its layout. The point of using Lorem Ipsum is that."
    },
    {
        id :4,
        name : "What makes the talent community unique?",
        para : "It is a long established fact that a the reader will be distracted by the readable content of a page when looking its layout. The point of using Lorem Ipsum is that."
    },
    {
        id :5,
        name : "How do I meet other?",
        para : "It is a long established fact that a the reader will be distracted by the readable content of a page when looking its layout. The point of using Lorem Ipsum is that."
    },
    {
        id :6,
        name : "Why is Toptal so popular among remote?",
        para : "It is a long established fact that a the reader will be distracted by the readable content of a page when looking its layout. The point of using Lorem Ipsum is that."
    },
    {
        id :7,
        name : "What fees does Freelancer charge?",
        para : "It is a long established fact that a the reader will be distracted by the readable content of a page when looking its layout. The point of using Lorem Ipsum is that."
    },
    {
        id :8,
        name : "Are there opportunities even for Makeup Artists?",
        para : "It is a long established fact that a the reader will be distracted by the readable content of a page when looking its layout. The point of using Lorem Ipsum is that."
    },
    {
        id :9,
        name : "Is there a salary range which I can select?",
        para : "It is a long established fact that a the reader will be distracted by the readable content of a page when looking its layout. The point of using Lorem Ipsum is that."
    },
    {
        id :10,
        name : "How can I choose the projects I wish to apply for?",
        para : "It is a long established fact that a the reader will be distracted by the readable content of a page when looking its layout. The point of using Lorem Ipsum is that."
    },
 

]