import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import ServiceDetails from '../../Components/ServiceDetails'
import useDocumentTitle from '../../PageTitle'

const ServiceDetailsPage = () => {
  useDocumentTitle("Portfolio | Service Details")
  return (
    <Layout>
        <BannerGlobal/>
        <ServiceDetails/>
    </Layout>
  )
}

export default ServiceDetailsPage