import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

const CounterUp = () => {
  const [counter, setCounter] = useState(false);
  return (
    <div className="counterUp py-16 lg:py-24">
      <div className=" grid grid-cols-1 gap-8 gap-y-24 lg:gap-y-0 md:grid-cols-2 lg:grid-cols-4">
        <div className="counter_box">
          <h2>
            <ScrollTrigger
              onEnter={() => setCounter(true)}
              onExit={() => setCounter(false)}
            >
              {counter && <CountUp start={0} end={36} duration={3} delay={0} />}
              <strong> +</strong>
            </ScrollTrigger>
          </h2>
          <p>Happy Clients</p>
        </div>
        <div className="counter_box">
        <h2>
            <ScrollTrigger
              onEnter={() => setCounter(true)}
              onExit={() => setCounter(false)}
            >
              {counter && <CountUp start={100} end={600} duration={3} delay={0} />}
              <strong> +</strong>
            </ScrollTrigger>
          </h2>
          <p>Complete Projects</p>
        </div>
        <div className="counter_box">
        <h2>
            <ScrollTrigger
              onEnter={() => setCounter(true)}
              onExit={() => setCounter(false)}
            >
              {counter && <CountUp start={100} end={420} duration={3} delay={0} />}
              <strong> +</strong>
            </ScrollTrigger>
          </h2>
          <p>Cups of Coffee</p>
        </div>
        <div className="counter_box">
        <h2>
            <ScrollTrigger
              onEnter={() => setCounter(true)}
              onExit={() => setCounter(false)}
            >
              {counter && <CountUp start={0} end={74} duration={3} delay={0} />}
              <strong> +</strong>
            </ScrollTrigger>
            </h2>
          <p>Nominees & Awards</p>
        </div>
      </div>
    </div>
  );
};

export default CounterUp;
