import React from "react";
import Layout from "../../Components/Layout";
import BannerGlobal from "../../Components/BannerGlobal";
import Blogs2 from "../../Components/Blogs2";
import useDocumentTitle from "../../PageTitle";

const BlogPage = () => {
  useDocumentTitle("Portfolio | Blogs")
  return (
    <Layout>
      <BannerGlobal />
      <Blogs2 />
    </Layout>
  );
};

export default BlogPage;
