import React from "react";
import { WorkData } from "../Data/WorkData";

const WorkProcess = () => {
  return (
    <div className="workProcess bg-troo-deepblack py-12">
      <div className="main_contaner ">
        <div className="text-center">
          <div className="sub_heading design">Proccess</div>
          <h4 className="headingMini mb-6">
            My Work Proccess
          </h4>
          <h2 className="textGradient">Transparency of Work</h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 py-12">
          {WorkData.map((e, i) => (
            <div className="border-b border-troo-hrtag pb-5" key={i}>
              <div className="service_card md:p-5 flex gap-5">
                <div className="">
                  <div className=" text-troo-white border border-troo-secondary rounded-full flex justify-center items-center w-[50px] h-[50px]">
                    0{e.id}
                  </div>
                </div>
                <div>
                  <h3 className="mb-5">{e.name} </h3>
                  <p>{e.para}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WorkProcess;
