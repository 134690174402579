import React from "react";
import profile1 from "../Images/profile1.png";
import { Link } from "react-router-dom";

const Banner = () => {
  return (
    <div className="bg-troo-primary bannerBG">
      <div className="main_contaner ">
        <div className="grid grid-cols-1 md:grid-cols-2 pt:12 2xl:pt-24">
          <div className="my-8 lg:my-14">
            <div className="design text-[70px] lg:text-[100px]">DESIGN</div>
            <h6>Hello, I’M</h6>
            <h1 className="textGradient">Alexa Smith</h1>
            <div className="para">
              Professional
              <span className="font-[700]">Freelancer and Web Designer</span>
            </div>
            <p className="py-5 mb-4">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took
            </p>
            <Link className="btn-theme-secondary btn-theme-lg " to="/contact-me">Contact Me</Link>
            <h5 className="mt-12 2xl:mt-32">
              Facebook - Instagram - Twitter - Behance - Dribbble
            </h5>
          </div>
          <div className="home_img flex justify-center z-[1]">
            <div className="design text-[32px] md:text-[50px] lg:text-[80px] absolute z-[-1] m-auto  lg:top-52">
              Development
            </div>
            <img className="w-full heroImg" src={profile1} alt="profile1" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
